import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleScholar from '@mui/icons-material/School';
import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://github.com/0x365" target="_blank" rel="noreferrer"><GitHubIcon/></a>
        <a href="https://www.linkedin.com/in/robert-cowlishaw/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
        <a href="https://scholar.google.com/citations?user=vIQ1FBcAAAAJ&hl=en" target="_blank" rel="noreferrer"><GoogleScholar/></a>
      </div>
    </footer>
  );
}

export default Footer;