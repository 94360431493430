import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsBackend = [
    "Go",
    "Solidity",
    "Rust",
    "C++",
    "Linux",
    "Hardhat",
    "Remix",
    "Docker",
];

const labelsFrontend = [
    "Ethers.js",
    "Web3.js",
    "Metamask",

    "React",
    "NodeJS",
    "NPM",
    "Nginx",
    
    "FastAPI",
    "Adminer",
    "Grafana",
    "MySQL",
    "SQLite",
    "PostgreSQL",
    "MariaDB",
    "MongoDB",
];

const labelsML = [
    "Sentinel-Hub",
    "PyTorch",
    "CUDA",
    "Scikit",
    "Scipy",
    "Hugging Face",
    "Matplotlib",
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faEthereum} size="3x"/>
                    <h3>Distributed Ledger Technologies</h3>
                    <p>Development of Distributed Ledger Technology (DLT) and other Web3 systems from the architecture itself to Smart Contracts executed upon blockchains.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsBackend.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>Frontend Interfacing with Distributed Systems</h3>
                    <p>Once the DLT architecture is built, I build bridges between Web2 and Web3 to help regular users connect to the next generation of the internet securely and safely.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsFrontend.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faPython} size="3x"/>
                    <h3>Earth Observation ML</h3>
                    <p>Automating Earth Observation analytics for more accurate and faster Natural Disaster Response. From classification and segmentation tasks to knowledge extrapolation using LLMs.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsML.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;